"use client";

import React, { useState, useEffect, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Image from "@/components/common/Image";
import styles from "./Carousel.module.scss";
import If from "@/components/common/If";
import { InlineVideo } from "@/components/common/InlineVideo";

interface CarouselImage {
  url: string;
  description: string;
  height: number;
  width: number;
}

interface CarouselProps {
  images: CarouselImage[];
  autoScrollDelay?: number;
}

const Carousel: React.FC<CarouselProps> = ({
  images,
  autoScrollDelay = 5000,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const autoScrollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const startAutoScroll = () => {
    if (emblaApi) {
      autoScrollIntervalRef.current = setInterval(() => {
        if (emblaApi.canScrollNext()) {
          emblaApi.scrollNext();
        } else {
          emblaApi.scrollTo(0);
        }
      }, autoScrollDelay);
    }
  };

  const stopAutoScroll = () => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
  };

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };

    emblaApi.on("select", onSelect);
    onSelect();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            startAutoScroll();
          } else {
            stopAutoScroll();
          }
        });
      },
      { threshold: 0.7 }
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      emblaApi.off("select", onSelect);
      stopAutoScroll();
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, [emblaApi, autoScrollDelay]);

  return (
    <div className={styles.carousel} ref={carouselRef}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {images.map((image, index) => (
            <div className={styles.slide} key={index}>
              <If condition={!image?.url.includes("mp4")}>
                <Image
                  src={image.url}
                  alt={image.description}
                  width={image.width}
                  height={image.height}
                />
              </If>
              <If condition={image?.url.includes("mp4")}>
                <InlineVideo
                  src={image.url}
                  alt={image.description}
                  width={image.width}
                  height={image.height}
                />
              </If>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.dots}>
        {images.map((_, index) => (
          <button
            key={index}
            className={`${styles.dot} ${
              index === selectedIndex ? styles.selected : ""
            }`}
            onClick={() => emblaApi?.scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
