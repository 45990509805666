import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.2.0_react-dom@19.0.0_react@19.0.0_sass@1.85.1/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/actions/Button/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/AnimateIn.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/AnimateTitle.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InlineVideo"] */ "/vercel/path0/src/components/common/InlineVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/form/TextInput/TextInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/SplitBlock/SplitBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Heading/Heading.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Work/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Work/Work.module.scss");
