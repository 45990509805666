"use client";

import { Pause, Play, Volume, Volume2, VolumeOff } from "lucide-react";
import { useEffect, useRef, useState } from "react";

export const InlineVideo = ({ src = "", className = "", ...props }) => {
  const observerRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!observerRef.current) return;
          if (entry.isIntersecting) {
            observerRef.current.play();
          } else {
            observerRef.current.pause();
          }
        });
      },
      { threshold: 0.35 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  const toggleMute = () => {
    if (observerRef.current) {
      observerRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="relative w-full flex items-center justify-start">
      <div className="w-full shrink-0">
        <video
          ref={observerRef}
          className={`${className} w-full object-contain max-h-[80dvh] object-left`}
          autoPlay
          loop
          muted={isMuted}
          playsInline
          src={src}
          {...props}
        />
        <button
          onClick={toggleMute}
          className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        >
          {isMuted ? <VolumeOff /> : <Volume2 />}
        </button>
      </div>
    </div>
  );
};
